<template lang="pug">
.brand-wrapper
  .row(v-if="loading" style="height: calc(100vh - 8rem)")
    loading-logo
  .container-fluid.newsletter-preferences(v-else)
    .newsletter-preferences-header.text-center
      .brand-logo
        om-logo-svg(width="252" height="49")
      .title Email Subscription Preferences
      .description You’re the boss. Keep the email subscriptions you like, get rid of the rest. Check the types of emails you want to receive. We’ll use this information to make sure we’re only sending you content you’re interested in.

    .newsletter-preferences-form-wrapper
      template(v-if="afterSave")
        .newsletter-preferences-message Success! Your email preferences have been updated.
      template(v-else-if="!isValidLink")
        .newsletter-preferences-message.text-center Invalid link!
      template(v-else)
        .newsletter-preferences-form
          .label-row Email
          .input-row
            om-input#email(type="text" v-model="email" disabled)
          .label-row Email Notifications
          template(v-for="(interest, index) in interests")
            .d-flex.align-items-center.interest-row
              input(type="checkbox" v-model="interest.value")
              label.mb-0.ml-2 {{ interest.name }}
          .submit-row
            invisible-recaptcha(
              v-if="siteKey"
              :sitekey="siteKey"
              :class="updateButtonClasses"
              :callback="submitPreferences"
            ) Update my preferences
            om-button.update-button(
              v-else
              primary
              large
              type="submit"
              @click.prevent="submitPreferences"
              :loading="saving"
            ) Update my preferences
          .unsub-row
            span If you'd like to unsubscribe entirely,
            span.unsubscribe-link.ml-1(@click="unsubscribe") click here.
</template>

<script>
  import axios from 'axios';
  import { apiBaseUrl } from '@/config/url';
  import runtimeConfig from '@/config/runtime';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import InvisibleRecaptcha from 'vue-invisible-recaptcha';

  const SITE_KEY = runtimeConfig.VUE_APP_RECAPTCHA_SITE_KEY;

  const INTERESTS_NAMES = {
    Tips: 'CRO tips and best practices',
    ProductUpdates: 'Product updates and news',
  };

  export default {
    name: 'NewsletterPreferences',
    components: { InvisibleRecaptcha },
    data() {
      return {
        loading: true,
        saving: false,
        afterSave: false,
        siteKey: SITE_KEY,
        email: '',
        interests: [],
        isValidLink: false,
      };
    },
    computed: {
      updateButtonClasses() {
        return getBrandedClassString({ primary: true, large: true }, [
          'update-button',
          'design-system',
        ]);
      },
      emailFromUrl() {
        return this.$route.query.email ?? null;
      },
      emailIdFromUrl() {
        return this.$route.query.emailid ?? null;
      },
      listIdFromUrl() {
        return this.$route.query.listid ?? null;
      },
    },
    async mounted() {
      this.loading = true;
      this.email = this.emailFromUrl;
      this.emailId = this.emailIdFromUrl;
      this.listId = this.listIdFromUrl;
      if (!this.email || !this.emailId || !this.listId) {
        this.isValidLink = false;
        this.loading = false;
        return;
      }
      this.interests = await this.getInterests();
      this.loading = false;
    },
    methods: {
      async getInterests() {
        let result;
        try {
          result = await axios.get(
            `${apiBaseUrl}/newsletter/mailchimp/member-interests/${this.listId}/${this.emailId}`,
          );
        } catch (error) {
          return [];
        }

        if (!result?.data?.success || result?.data?.statuses.length < 1) {
          return [];
        }

        this.isValidLink = true;

        return result?.data?.statuses?.map((row) => {
          return {
            type: row.type,
            name: INTERESTS_NAMES[row.type] ?? '',
            value: row.status === 'subscribed',
          };
        });
      },
      async unsubscribe() {
        const interests = this.interests.map((interest) => {
          return {
            type: interest.type,
            value: false,
          };
        });

        return this.sendPreferences(interests);
      },
      submitPreferences() {
        const interests = this.interests.map((interest) => {
          return {
            type: interest.type,
            value: interest.value,
          };
        });

        return this.sendPreferences(interests);
      },
      async sendPreferences(interests) {
        if (this.saving) return;

        this.saving = true;

        const result = await axios.post(
          `${apiBaseUrl}/newsletter/mailchimp/member-interests/${this.listId}/${this.emailId}`,
          { interests },
        );

        if (!result?.data?.success) {
          this.saving = false;
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
          });
          return;
        }

        this.saving = false;
        this.afterSave = true;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../sass/variables/_colors'

  .brand-wrapper
    background-color:#fff7f2

  .newsletter-preferences
    color: rgb(80, 87, 99)
    max-width: 780px
    margin-top: 36px

  .brand-logo

  .title
    color: rgb(35, 38, 42)
    font-weight: bold
    font-size: 40px
    line-height: 150%
    margin-top: 35px
    margin-bottom: 20px

  .description
    padding: 18px 0
    font-size: 18px
    line-height: 27px

  .newsletter-preferences-form
    width: 390px
    padding: 18px
    margin-top: 9px

  .newsletter-preferences-message
    width: 390px
    padding: 18px
    margin-top: 9px
    font-size: 18px
    line-height: 27px
    font-weight: 700

  .newsletter-preferences-form-wrapper
    display: flex
    justify-content: center

  .label-row
    font-size: 15px
    font-weight: 700
    padding-bottom: 6px

  .input-row
    padding-bottom: 24px

  .interest-row
    font-size: 15px
    padding: 9px 0

  .unsub-row
    font-style: italic

  .submit-row
    padding: 18px 0

  .unsub-row
    padding: 9px 0

  .unsubscribe-link
    cursor: pointer
    color: $om-orange-500
    text-decoration: underline
    &:hover
      color: $om-orange-500

  .update-button
    width: 100%
    font-size: 16px
</style>
